<script setup>
import { ref } from 'vue'
import ChildComp from './components/ChildComp.vue'

//const greeting = ref('Hello from parent')

//import ChildComp from './todo-fetch.vue'
//import FormKitToppings from './example-two.vue' // error 
//import ResourceLinks from './components/ResourceLinks.vue'
import ToDoDemo from './components/ToDoDemo.vue'
import HelloWorld from './components/HelloWorld.vue'
//import FormKitDemo from './formkit.vue'
//import ChildComp from './ChildComp.vue'
import FormKitBasicDemo from './components/FormKitBasicDemo.vue'
import FormKitDemos from './components/FormKitDemos.vue'
import FormKitToppings from './components/FormKitToppings.vue'

import MiscDemos from './components/MiscDemos.vue'
import TailwindDemos from './components/TailwindDemos.vue'

import EmitterDemo from './components/EmitterDemo.vue'
import ConfettiDemo from './components/ConfettiDemo.vue'

import BasicsDemo from './components/BasicsDemo.vue'
import BasicsForms from './components/BasicsForms.vue'
//import MarkdownEditor from './components/MarkdownEditor.vue'

import TreeItems from './components/TreeItems.vue'
import GraphDemo from './components/Graph/GraphDemo.vue'
import PopupBoxes from './components/PopupBoxes.vue'
import ListView from './components/Widgets/ListView.vue'
//import ToDoApp from './components/Apps/TodoApp.vue'
import CrudApp from './components/Apps/CrudApp.vue'
import CircleDrawer from './components/Drawing/CircleDrawer.vue'
import SpreadsheetApp from './components/Spreadsheet/SpreadsheetApp.vue'

const greeting = ref('Hello from parent')

</script>

<template>
  <h1>Basic Demos</h1>

  
  <!-- <ToDoApp /> -->

  <!-- <MarkdownEditor /> -->
  
  <div class="demos">
    <SpreadsheetApp />
    <CircleDrawer />
    <CrudApp />
    <BasicsDemo />
    <ListView />
    <PopupBoxes/>    
    <GraphDemo />
    <TreeItems />

    <BasicsForms />
    <!-- <ResourceLinks /> -->
    
    <EmitterDemo />
    <ChildComp :msg="greeting" />
    <ConfettiDemo />
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <MiscDemos />
    <TailwindDemos />

  </div>

  <h1>Form Kit Demos</h1>

  <div class="demos">    
    <FormKitBasicDemo />
    <FormKitToppings />
    <FormKitDemos />
    <!-- <FormKitDemo /> -->
  </div>  

  <ToDoDemo />

</template>

<style style="text/css">
h1 { 
  font-size: large;
  font-weight: bold;
}

</style>