import { createApp } from 'vue'

import { plugin, defaultConfig } from '@formkit/vue'
import './main.css';

// import App from './App.vue'
// import App1 from './App1.vue'
// import App2 from './todo-demo.vue'
// import App3 from './example-one.vue'
// import App4 from './formkit.vue'
import AppDemos from './AppDemos.vue'
//import AppDemos from './StartApp.vue'

// createApp(App).mount('#app')

import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //{ path: '/hello', redirect: 'App1.vue' }
    // { path: '/app', component: App },
    // { path: '/app1', component: App1 },
    // { path: '/app2', component: App2 },
    // { path: '/app3', component: App3 },
    // { path: '/app4', component: App4 },
    { path: '/', component: AppDemos}
  ]
})

// createApp(App).use(router).mount('#app')

createApp(AppDemos).use(router).use(plugin, defaultConfig).mount('#app')
// remember, each Vue plugin needs its own .use()
// .use(router).use(plugin2)