<script>
//import { createForm } from '@formkit/core';
//import { createForm } from '@formkit/core';

export default {
  name: 'fk-demo',
  // data() {
  //   return {
  //     form: createForm({
  //       fields: {
  //         name: {
  //           value: '',
  //           validators: [(value) => !!value || 'Name is required']
  //         }
  //       }
  //     })
  //   };
  // },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.form.submit().then(() => {
        if (this.form.isValid) {
          // handle successful form submission
        }
      });
    }
  }
};
</script>

<template>
  <div class="demo">

    <h1>Basic Form</h1>
    
    <form @submit="submitForm">
      <!-- <input v-model="form.fields.name.value" type="text" placeholder="Name" /> -->
      <FormKit type="checkbox"/>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>


