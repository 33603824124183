<!--
Modal component with customizable slots and CSS transitions.
-->

<script setup>
import Modal from './Widgets/ModalBox.vue'
import { ref } from 'vue'

const showModal = ref(false)
</script>

<template>
    <div class="demo">
    <h1>Modal Popup</h1>
  <button id="show-modal" class="btn" @click="showModal = true">Show Modal</button>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <h3>custom header</h3>
      </template>
    </modal>
  </Teleport>
  </div>
</template>