<script setup>
import { defineProps } from 'vue'
import { defineEmits } from 'vue'

defineProps({
  msg: String
})

// emitter demo
const emit = defineEmits(['response'])
emit('response', 'hello from child')

</script>

<template>
<div class=demo>
  <h2>ChildComp: Property based</h2>
  <b>{{ msg || 'No props passed yet' }}</b>
</div>
</template>