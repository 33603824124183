<!--
  We can create two-way bindings between state and form inputs using the v-model directive.

https://vuejs.org/examples/#form-bindings
-->

<script setup>
// imports must be at top
import { ref } from 'vue'
import TodoItem from './TodoItem.vue'
import DemoGrid from './Widgets/GridView.vue'
import TemperatureConverter from './Widgets/TemperatureConverter.vue'

const text = ref('Edit me')
const checked = ref(true)
const checkedNames = ref(['Jack'])
const picked = ref('One')
const selected = ref('A')
const multiSelected = ref(['A'])

function btnPress(){
  text.value = "Hello from button press"
  //alert("Hello")
}


const groceryList = ref([
  { id: 0, text: 'Vegetables' },
  { id: 1, text: 'Cheese' },
  { id: 2, text: 'Whatever else humans are supposed to eat' }
])


// grid view demo


const searchQuery = ref('')
const gridColumns = ['name', 'power']
const gridData = [
  { name: 'Chuck Norris', power: Infinity },
  { name: 'Bruce Lee', power: 9000 },
  { name: 'Jackie Chan', power: 7000 },
  { name: 'Jet Li', power: 8000 }
]


</script>

<template>
    <div class="demo">
      <h1>Temperature Converter</h1>
      <TemperatureConverter/>
    </div>


    <div class="demo">
        <h1>Grid Demo</h1>
        
        <form id="search">
            Search <input name="query" v-model="searchQuery">
        </form>
        <DemoGrid
            :data="gridData"
            :columns="gridColumns"
            :filter-key="searchQuery">
        </DemoGrid>
    </div>

    <div class="demo">
        <h1>Basic Component</h1>
        <ol>
    <!--
      We are providing each todo-item with the todo object
      it's representing, so that its content can be dynamic.
      We also need to provide each component with a "key",
      which is explained in the guide section on v-for.
    -->
    <TodoItem
      v-for="item in groceryList"
      :todo="item"
      :key="item.id"
    ></TodoItem>
  </ol>
    </div>


    <div class="demo">
  <h2>Text Input</h2>
  <input v-model="text"> {{ text }}


  <button class="btn" @click="btnPress">
    Press me
  </button>

  <h2>Checkbox</h2>
  <input type="checkbox" id="checkbox" v-model="checked">
  <label for="checkbox">Checked: {{ checked }}</label>

  <!--
    multiple checkboxes can bind to the same
    array v-model value
  -->
  <h2>Multi Checkbox</h2>
  <input type="checkbox" id="jack" value="Jack" v-model="checkedNames">
  <label for="jack">Jack</label>
  <input type="checkbox" id="john" value="John" v-model="checkedNames">
  <label for="john">John</label>
  <input type="checkbox" id="mike" value="Mike" v-model="checkedNames">
  <label for="mike">Mike</label>
  <p>Checked names: <pre>{{ checkedNames }}</pre></p>

  <h2>Radio</h2>
  <input type="radio" id="one" value="One" v-model="picked">
  <label for="one">One</label>
  <br>
  <input type="radio" id="two" value="Two" v-model="picked">
  <label for="two">Two</label>
  <br>
  <span>Picked: {{ picked }}</span>

  <h2>Select</h2>
  <select v-model="selected">
    <option disabled value="">Please select one</option>
    <option>A</option>
    <option>B</option>
    <option>C</option>
  </select>
  <span>Selected: {{ selected }}</span>

  <h2>Multi Select</h2>
  <select v-model="multiSelected" multiple style="width:100px">
    <option>A</option>
    <option>B</option>
    <option>C</option>
  </select>
  <span>Selected: {{ multiSelected }}</span>
</div>

</template>