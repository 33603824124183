<!--
FLIP list transitions with the built-in <TransitionGroup>.
https://aerotwist.com/blog/flip-your-animations/
-->

<script setup>
import { shuffle as _shuffle } from 'lodash-es'
import { ref } from 'vue'

const getInitialItems = () => [1, 2, 3, 4, 5]
const items = ref(getInitialItems())
let id = items.value.length + 1

function insert() {
  const i = Math.round(Math.random() * items.value.length)
  items.value.splice(i, 0, id++)
}

function reset() {
  items.value = getInitialItems()
}

function shuffle() {
  items.value = _shuffle(items.value)
}

function remove(item) {
  const i = items.value.indexOf(item)
  if (i > -1) {
    items.value.splice(i, 1)
  }
}
</script>

<template>
  <div class=demo>

  <h1>List View</h1>

  <button class="btn" @click="insert">insert at random index</button>
  <button class="btn" @click="reset">reset</button>
  <button class="btn" @click="shuffle">shuffle</button>

  <TransitionGroup tag="ul" name="fade" class="container">
    <div v-for="item in items" class="item" :key="item">
      {{ item }}
      <button class="btn" @click="remove(item)">x</button>
    </div>
  </TransitionGroup>
</div>
</template>

<style scoped>
.container {
  position: relative;
  padding: 0;
}
.btn {
  margin-right: 4px;
  margin-bottom: 4px;
}
.item {
  /* width: 100%;
  height: 50px;
  background-color: #f3f3f3;
  box-sizing: border-box;
  vertical-align: middle; */
  border: 1px solid #666;
  margin: 4px;
}

/* make .item .btn right aligned */
.item {
  display: flex;
  justify-content: space-between;
}
.item .btn {
  align-self: flex-start;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>