<script setup>
// npm install js-confetti
//import JSConfetti from 'js-confetti'
//const confetti = new JSConfetti()

import { ref } from 'vue'
const message = ref('')

function showConfetti() {
 // confetti.addConfetti()
 console.log('showConfetti');
 message.value = 'sorry no confetti yet'
}

//showConfetti()
</script>

<template>
    <div class="demo">

        <h1 @click="showConfetti">🎉 Congratulations!</h1>
        
        <h2>{{ message }}</h2>
    </div>
</template>

<style scoped>
h1 {
  text-align: center;
  cursor: pointer;
  margin-top: 3em;
}
</style>


<!--
Here we are reactively binding element attributes / properties to the state.
The :title syntax is short for v-bind:title.
-->

