<script setup>
import { ref } from 'vue'
import ChildComp from './ChildComp.vue'

// flow from child to parent
const childMsg = ref('No child msg yet')

// Emits
// https://vuejs.org/tutorial/#step-13
// flow from parent to child
const greeting = ref('Hello from parent')

</script>

<template>
  <div class="demo">
    <h1>Emitter demo</h1>
    <p>flow from child to parent</p>

    <!-- <ChildComp /> -->
    <ChildComp @response="(msg) => childMsg = msg" :msg="greeting" />
      
      <p>response: {{ childMsg }}</p>
    </div>
</template>