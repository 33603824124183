<script setup>
import { ref } from 'vue'

// ****************************
// TODO App 

// give each todo a unique id
let id = 0

const newTodo = ref('')
const todos = ref([
  { id: id++, text: 'Learn HTML' },
  { id: id++, text: 'Learn JavaScript' },
  { id: id++, text: 'Learn Vue' }
])

function addTodo() {
  todos.value.push({ id: id++, text: newTodo.value })
  newTodo.value = ''
}

function removeTodo(todo) {
  todos.value = todos.value.filter((t) => t !== todo)
}


</script>

<style scoped>
 body { 
  font-family: sans-serif;
  color: #333;
  margin: 10px;
 font-size: 80%;   
 }

 
 .demos {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   flex-wrap: wrap;
   max-width: 1200px;
 }

.demo {
  margin:10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: beige;
  width: 340px;
}
</style>

<template>
  
  <div class="demo">
    <h2>Todo</h2>
   
      <form @submit.prevent="addTodo">
        <input v-model="newTodo">
        <button>Add Todo</button>    
      </form>
      <ul>
        <li v-for="todo in todos" :key="todo.id">
          {{ todo.text }}
          <button @click="removeTodo(todo)">X</button>
        </li>
      </ul>

      <hr>
      This demo is based on <a href="https://vuejs.org/tutorial/#step-6">vuejs/tutorials</a>

  </div>  


</template>