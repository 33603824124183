<script setup>
import { ref } from 'vue'

const text = ref('')

// on change
function onInput(e) {
  text.value = e.target.value
}

// toggle
const awesome = ref(true)

function toggle() {
  awesome.value = !awesome.value
}

</script>


<template>

  <div class="demo">
      <h2>Interactive dom binding</h2>
    <input :value="text" @input="onInput" placeholder="Type here">
    <p>{{ text }}</p>
  </div>

  

  <div class="demo">
    <h2>Conditional rendering</h2>

    <button @click="toggle">toggle</button>
    <h1 v-if="awesome">Vue is awesome!</h1>
    <h1 v-else>Oh no 😢</h1>
  </div>

 
</template>