<!--
This example demonstrates handling user input with the v-on directive.
-->

<script setup>
import { ref } from 'vue'
import FlightBooker from './Apps/FlightBooker.vue';
import TimerWidget from './Widgets/TimerWidget.vue';
// demo 2

const message2 = ref('Hello World!')
const isRed = ref(true)
const color = ref('green')
const message = ref('Hello World!')
const count = ref(0)

function reverseMessage() {
  // Access/mutate the value of a ref via
  // its .value property.
  message.value = message.value.split('').reverse().join('')
}

function notify() {
  alert('navigation was prevented.')
}

// demo 2


function toggleRed() {
  isRed.value = !isRed.value
}

function toggleColor() {
  color.value = color.value === 'green' ? 'blue' : 'green'
}

// demo 3
const show = ref(true)
const list = ref([1, 2, 3])


</script>

<template>
  <div class="demo">
  <h1>User Input</h1>
  
  <!--
    Note we don't need .value inside templates because
    refs are automatically "unwrapped" in templates.
  -->
  <h2>{{ message }}</h2>

  <!--
    Bind to a method/function.
    The @click syntax is short for v-on:click.
  -->
  <button class="btn" @click="reverseMessage">Reverse Message</button>

  <!-- Can also be an inline expression statement -->
  <button class="btn" @click="message += '!'">Append "!"</button>

  <!--
    Vue also provides modifiers for common tasks
    such as e.preventDefault() and e.stopPropagation()
  -->
  <a class="btn" href="https://vuejs.org" @click.prevent="notify">
    A link with e.preventDefault()
  </a>
  
  <a 
  class="btn"
  href="https://vuejs.org/examples/">vuejs examples</a>

  </div>

<div class=demo>
  <h1>Counter</h1>

  {{ count }}
  <button @click="count++">Count</button>

</div>

<!--
Here we are reactively binding element attributes / properties to the state.
The :title syntax is short for v-bind:title.
-->


  <div class="demo">
  
  <p>
    <span :title="message2">
      Hover your mouse over me for a few seconds to see my dynamically bound title!
    </span>
  </p>

  <!--
  class bindings have special support for objects and arrays
  in addition to plain strings
  -->
  <p :class="{ red: isRed }" @click="toggleRed">
    This should be red... but click me to toggle it.
  </p>

  <!-- style bindings also support object and arrays -->
  <p :style="{ color }" @click="toggleColor">
    This should be green, and should toggle between green and blue on click.
  </p>

  </div>

  <div class="demo">
    <h1>click demo</h1>
  <button @click="show = !show">Toggle List</button>
  <button @click="list.push(list.length + 1)">Push Number</button>
  <button @click="list.pop()">Pop Number</button>
  <button @click="list.reverse()">Reverse List</button>

  <ul v-if="show && list.length">
    <!-- <li v-for="item of list">{{ item }}</li> -->

    <li v-for="(item, index) in list" :key="index">
      {{ item }}
    </li>

  </ul>
  <p v-else-if="list.length">List is not empty, but hidden.</p>
  <p v-else>List is empty.</p>
  </div>


  <div class="demo">
    <h1>Flight Booker</h1>
    <FlightBooker />
  </div>

  <div class="demo">
    <h1>Timer</h1>
    <TimerWidget />
  </div>
  
</template>

<style scoped>
  .red {
    color: red;
  }
.btn { 
  display: block;
  margin-bottom: 1em;
  min-width: 200px;
  max-width: 100px;
}

button{ 
  display: inline-block;
  background-color: blue;
  color: white;
  margin-bottom: 1em;  
  max-width: 60px;
  margin: 3px;
}
</style>